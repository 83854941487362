/* eslint-disable camelcase */

import { useQuery } from 'react-query'
import { client } from './AxiosClient'
import { KEYS } from './keys'

const isBrowser = typeof window !== 'undefined'

const getBrandingTenantFromHostname = () => {
  return isBrowser
    ? window.location.hostname.replace(/\..*/, '')
    : 'app'
}

export const useGetCnameBrandingQuery = () => {
  const cname = getBrandingTenantFromHostname()
  return useQuery(KEYS.BRANDING_CNAME(cname), () => {
    return client.get(`/api/v2/branding/${cname}`).then(res => res.data)
  }, {
    staleTime: 1000 * 60,
    cacheTime: 1000 * 60,
    placeholderData: {
      dnsCname: 'app',
      companyLogo: undefined,
      companyName: 'ReflowHub',
      companyTagline: 'Device lifecycle, simplified.',
      companyColour: '#A70F4C',
      emailTemplateListId: 'List Number',
      emailTemplateSignOff: 'The ReflowHub Team'
    }
  })
}
